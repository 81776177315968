// Variables
// =============================================================================
$width-sm: $container-width-small;
$width-md: $container-width-medium;
$width-lg: $container-width-large;

// WIDTH
// =============================================================================
.width--sm {
    width: $width-sm !important;
}

.width--md {
    width: $width-md !important;
}

.width--lg {
    width: $width-lg !important;
}

.width--0 {
    width: 0% !important;
}

.width--25 {
    width: 25% !important;
}

.width--33 {
    width: 33.3333333% !important;
}

.width--50 {
    width: 50% !important;
}

.width--66 {
    width: 66.6666667% !important;
}

.width--75 {
    width: 75% !important;
}

.width--100 {
    width: 100% !important;
}

.width--auto {
    width: auto;
}

// MAX-WIDTH
// =============================================================================
.width--fit {
    max-width: 100% !important;
}

// Min-width utilities
// Mainly used with flex elements that need to have truncated content.
// =============================================================================
.width-min--0 {
    min-width: 0 !important;
}
