// Lists and maps
// =============================================================================
$directions: left, right, top, bottom;
$steps: sm, md, lg, xl, "2xl", "3xl", "4xl", nano, tiny, small, base, big, huge, gigantic;
$percentages: 0, 25, 33, 50, 66, 75;
$axis: x, y;

$margins: (
    margin-sm: $spacingPlatformNano,
    margin-md: $spacingPlatformTiny,
    margin-lg: $spacingPlatformSmall,
    margin-xl: $spacingPlatformBase,
    margin-2xl: $spacingPlatformBig,
    margin-3xl: $spacingPlatformHuge,
    margin-4xl: $spacingPlatformGigantic,
    margin-nano: $spacingPlatformNano,
    margin-tiny: $spacingPlatformTiny,
    margin-small: $spacingPlatformSmall,
    margin-base: $spacingPlatformBase,
    margin-big: $spacingPlatformBig,
    margin-huge: $spacingPlatformHuge,
    margin-gigantic: $spacingPlatformGigantic,
    margin-0: 0,
    margin-25: 25%,
    margin-33: 33.3333333%,
    margin-50: 50%,
    margin-66: 66.6666667%,
    margin-75: 75%,
);

// All sides margin
// =============================================================================
@include spacingAround($steps, $percentages, "margin", "mar", $margins);

// Specific side margin
// =============================================================================
@include spacingDirectional(
    $steps,
    $percentages,
    "margin",
    "mar",
    $margins,
    $directions
);

// Axis margin
// =============================================================================
@include spacingAxis($steps, $percentages, "margin", "mar", $margins, $axis);

// Auto margin
// =============================================================================
.mar--auto {
    margin-left: auto;
    margin-right: auto;
}
