.block {
    display: block !important;
}

.inline-block {
    display: inline-block !important;
}

.flex {
    display: flex !important;
}

.inline-flex {
    display: inline-flex !important;
}

.hidden {
    display: none !important;
}
