// Base Surface Style
// =============================================================================
.base-surface-low-emphasis {
    background-color: $colorPlatformWhite;
    border: none;
    padding: $spacingPlatformBase;
}

.base-surface-low-emphasis--border {
    background-color: $colorPlatformWhite;
    border: 1px solid $colorPlatformGray100;
    padding: $spacingPlatformBase;
}

.base-surface-medium-emphasis {
    background-color: $colorPlatformGray25;
    border: none;
    padding: $spacingPlatformBase;
}

.base-surface-drop-zone {
    background-color: $colorBrandedInfoLight;
    border: 3px dashed $colorBrandedInfo;
}
