// These are the mixins used to produce the margin and padding utility classes.
// =============================================================================

/// The helper mixin to produce the utility classes for margin & padding on all sides.
/// @param {array<string>} $steps - List of steps to be used (e.g. sm, md, lg, etc.)
/// @param {array<number>} $percentages - List of percentages to be used (e.g. 0, 25, 50, etc.)
/// @param {string} $property - The utility property to be produced (e.g. margin)
/// @param {string} $prefix - The utility property to be produced (e.g. margin)
/// @param {map<string>} $map - The property values to be used in the generation of classes (e.g. #{$property}-sm: 5px)
@mixin spacingAround($steps, $percentages, $property, $prefix, $map) {
    @each $step in $steps {
        .#{$prefix}--#{$step} {
            #{$property}: map-get($map, #{$property}-#{$step}) !important;
        }

        .#{$prefix}-child--#{$step} > * {
            #{$property}: map-get($map, #{$property}-#{$step}) !important;
        }
    }

    @each $percentage in $percentages {
        .#{$prefix}--#{$percentage} {
            #{$property}: map-get($map, #{$property}-#{$percentage}) !important;
        }
    }
}

/// The helper mixin to produce the utility classes for margin & padding on specific sides.
/// @param {list<string>} $steps - List of steps to be used (e.g. sm, md, lg, etc.)
/// @param {list<number>} $percentages - List of percentages to be used (e.g. 0, 25, 50, etc.)
/// @param {string} $property - The utility property to be produced (e.g. margin)
/// @param {string} $prefix - The utility property to be produced (e.g. margin)
/// @param {map<string>} $map - The property values to be used in the generation of classes (e.g. #{$property}-sm: 5px)
/// @param {list<string>} $directions - The directions to be used for the helper classes (e.g. left, right, etc.)
@mixin spacingDirectional($steps, $percentages, $property, $prefix, $map, $directions) {
    @each $direction in $directions {
        @each $step in $steps {
            .#{$prefix}-#{$direction}--#{$step} {
                #{$property}-#{$direction}: map-get($map, #{$property}-#{$step}) !important;
            }

            .#{$prefix}-child-#{$direction}--#{$step} > * {
                #{$property}-#{$direction}: map-get($map, #{$property}-#{$step}) !important;
            }
        }

        @each $percentage in $percentages {
            .#{$prefix}-#{$direction}--#{$percentage} {
                #{$property}-#{$direction}: map-get($map, #{$property}-#{$percentage}) !important;
            }
        }
    }
}

/// The helper mixin to produce the utility classes for margin & padding on a specific axis.
/// @param {list<string>} $steps - List of steps to be used (e.g. sm, md, lg, etc.)
/// @param {list<number>} $percentages - List of percentages to be used (e.g. 0, 25, 50, etc.)
/// @param {string} $property - The utility property to be produced (e.g. margin)
/// @param {string} $prefix - The utility property to be produced (e.g. margin)
/// @param {map<string>} $map - The property values to be used in the generation of classes (e.g. #{$property}-sm: 5px)
/// @param {list<string>} $axis - The axis to be used for the helper classes (e.g. x, y)
@mixin spacingAxis($steps, $percentages, $property, $prefix, $map, $axis) {
    @each $ax in $axis {
        @each $step in $steps {
            .#{$prefix}-#{$ax}--#{$step} {
                @if $ax == 'x' {
                    #{$property}-left: map-get($map, #{$property}-#{$step}) !important;
                    #{$property}-right: map-get($map, #{$property}-#{$step}) !important;
                }

                @else {
                    #{$property}-bottom: map-get($map, #{$property}-#{$step}) !important;
                    #{$property}-top: map-get($map, #{$property}-#{$step}) !important;
                }
            }

            .#{$prefix}-child-#{$ax}--#{$step} > * {
                @if $ax == 'x' {
                    #{$property}-left: map-get($map, #{$property}-#{$step}) !important;
                    #{$property}-right: map-get($map, #{$property}-#{$step}) !important;
                }

                @else {
                    #{$property}-bottom: map-get($map, #{$property}-#{$step}) !important;
                    #{$property}-top: map-get($map, #{$property}-#{$step}) !important;
                }
            }
        }

        @each $percentage in $percentages {
            .#{$prefix}-#{$ax}--#{$percentage} {
                @if $ax == 'x' {
                    #{$property}-left: map-get($map, #{$property}-#{$percentage}) !important;
                    #{$property}-right: map-get($map, #{$property}-#{$percentage}) !important;
                }

                @else {
                    #{$property}-bottom: map-get($map, #{$property}-#{$percentage}) !important;
                    #{$property}-top: map-get($map, #{$property}-#{$percentage}) !important;
                }
            }
        }
    }
}
