$grid-gutter-widths: (
  xs: 30px
) !default;

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
  @include media-breakpoint-up($grid-breakpoint) {
    $grid-gutter-half-width: $grid-gutter-width / 2;
    .row:not(.no-gutters) {
      margin-right: -$grid-gutter-half-width;
      margin-left: -$grid-gutter-half-width;
    }
    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*="col-"] {
      padding-right: $grid-gutter-half-width;
      padding-left: $grid-gutter-half-width;
    }
  }
}