
// Platform Colors //
$colorBrandedCautionTimeoutLight: rgb(245, 221, 209);
$colorBrandedErrorTimeoutLight: rgb(245, 221, 209);
$colorBrandedInfoTimeout: rgb(2, 81, 151);
$colorBrandedInfoTimeoutLight: rgb(208, 227, 246);
$colorBrandedSuccessTimeout: rgb(20, 92, 39);
$colorBrandedCautionTimeout: rgb(158, 68, 15);
$colorBrandedSuccessTimeoutLight: rgb(215, 234, 223);
$colorBrandedErrorTimeout: rgb(170, 18, 18);
$colorBrandedBulletin: rgb(255, 236, 181);
$colorBrandedCaution: rgb(196, 84, 19);
$colorBrandedCautionLight: rgb(252, 246, 243);
$colorBrandedError: rgb(206, 22, 22);
$colorBrandedErrorLight: rgb(252, 243, 243);
$colorBrandedInfo: rgb(2, 103, 193);
$colorBrandedInfoLight: rgb(242, 247, 252);
$colorBrandedSuccess: rgb(27, 124, 53);
$colorBrandedSuccessLight: rgb(243, 248, 245);
$colorPlatformAlpha25: 0.96;
$colorPlatformAlpha50: 0.88;
$colorPlatformAlpha75: 0.76;
$colorPlatformWhite: rgb(255, 255, 255);
$colorPlatformGray25: rgb(250, 250, 250);
$colorPlatformGray50: rgb(242, 242, 242);
$colorPlatformGray100: rgb(230, 230, 230);
$colorPlatformGray200: rgb(204, 204, 204);
$colorPlatformGray300: rgb(179, 179, 179);
$colorPlatformGray400: rgb(153, 153, 153);
$colorPlatformGray500: rgb(117, 117, 117);
$colorPlatformGray600: rgb(102, 102, 102);
$colorPlatformGray700: rgb(77, 77, 77);
$colorPlatformGray800: rgb(51, 51, 51);
$colorPlatformGray900: rgb(26, 26, 26);

// Guidance Colors //
$colorBrandedGuidance: rgb(115, 115, 115);
$colorBrandedGuidance50: rgb(242, 242, 242);
$colorBrandedGuidance100: rgb(230, 230, 230);
$colorBrandedGuidance200: rgb(204, 204, 204);
$colorBrandedGuidance300: rgb(179, 179, 179);
$colorBrandedGuidance400: rgb(153, 153, 153);
$colorBrandedGuidance500: rgb(117, 117, 117);
$colorBrandedGuidance600: rgb(102, 102, 102);
$colorBrandedGuidance700: rgb(77, 77, 77);
$colorBrandedGuidance800: rgb(51, 51, 51);
$colorBrandedGuidance900: rgb(26, 26, 26);
$colorBrandedGuidanceAccessible: rgb(102, 102, 102);
$colorBrandedGuidanceOnColor: rgb(255, 255, 255);
$colorBrandedGuidanceHover: rgb(102, 102, 102);
$colorBrandedGuidanceHoverOnColor: rgb(242, 242, 242);
$colorBrandedGuidancePressed: rgb(77, 77, 77);
$colorBrandedGuidancePressedOnColor: rgb(242, 242, 242);

// Affordance Colors //
$colorBrandedAffordance: rgb(204, 204, 204);
$colorBrandedAffordance50: rgb(242, 242, 242);
$colorBrandedAffordance100: rgb(230, 230, 230);
$colorBrandedAffordance200: rgb(204, 204, 204);
$colorBrandedAffordance300: rgb(179, 179, 179);
$colorBrandedAffordance400: rgb(153, 153, 153);
$colorBrandedAffordance500: rgb(117, 117, 117);
$colorBrandedAffordance600: rgb(102, 102, 102);
$colorBrandedAffordance700: rgb(77, 77, 77);
$colorBrandedAffordance800: rgb(51, 51, 51);
$colorBrandedAffordance900: rgb(26, 26, 26);
$colorBrandedAffordanceAccessible: rgb(102, 102, 102);
$colorBrandedAffordanceOnColor: rgb(77, 77, 77);
$colorBrandedAffordanceHover: rgb(102, 102, 102);
$colorBrandedAffordanceHoverOnColor: rgb(242, 242, 242);
$colorBrandedAffordancePressed: rgb(77, 77, 77);
$colorBrandedAffordancePressedOnColor: rgb(204, 204, 204);

// Account Colors //
$colorBrandedAccountColor0: rgb(7, 152, 209);
$colorBrandedAccountColor1: rgb(111, 156, 47);
$colorBrandedAccountColor2: rgb(217, 109, 1);
$colorBrandedAccountColor3: rgb(255, 199, 3);
$colorBrandedAccountColor4: rgb(54, 68, 150);
$colorBrandedAccountColor5: rgb(73, 175, 166);
$colorBrandedAccountColor6: rgb(160, 40, 134);
$colorBrandedAccountColor7: rgb(103, 51, 0);
$colorBrandedAccountColorOnColor0: rgb(0, 0, 0);
$colorBrandedAccountColorOnColor1: rgb(0, 0, 0);
$colorBrandedAccountColorOnColor2: rgb(0, 0, 0);
$colorBrandedAccountColorOnColor3: rgb(0, 0, 0);
$colorBrandedAccountColorOnColor4: rgb(255, 255, 255);
$colorBrandedAccountColorOnColor5: rgb(0, 0, 0);
$colorBrandedAccountColorOnColor6: rgb(255, 255, 255);
$colorBrandedAccountColorOnColor7: rgb(255, 255, 255);

// Surface Elevation //
$surfacePlatformRaisedBackgroundColor: rgb(255, 255, 255);
$surfacePlatformRaisedShadowColor: rgb(26, 26, 26);
$surfacePlatformRaisedShadowBlurResting: 24px;
$surfacePlatformRaisedShadowBlurLifted: 40px;
$surfacePlatformRaisedShadowBlurPressed: 8px;
$surfacePlatformRaisedShadowY: 8;
$surfacePlatformRaisedShadowOpacity: 24%;
$surfacePlatformRaisedShadowSpread: -8;
$surfaceBrandedBackgroundColor: rgb(255, 255, 255);
$surfaceBrandedBaseColor: rgb(255, 255, 255);
$surfaceBrandedBaseBorderColor: rgb(255, 255, 255);
$surfaceBrandedRaisedShadowColor: rgb(26, 26, 26);

// Platform Elevation //
$elevationPlatformBackgroundSurfaceBackgroundColor: rgb(255, 255, 255);
$elevationPlatformBackgroundSurfaceBorderColor: none;
$elevationPlatformBaseSurfaceBackgroundColor: rgb(255, 255, 255);
$elevationPlatformBaseSurfaceBorderColor: rgb(242, 242, 242);
$elevationPlatformRestingSurfaceBackgroundColor: rgb(255, 255, 255);
$elevationPlatformRestingSurfaceBorderColor: none;
$elevationPlatformRestingSurfaceBoxShadow: 0 4px 24px 0 rgba(26, 26, 26, 0.24);
$elevationPlatformLiftedSurfaceBackgroundColor: rgb(255, 255, 255);
$elevationPlatformLiftedSurfaceBorderColor: none;
$elevationPlatformLiftedSurfaceBoxShadow: 0 4px 40px 0 rgba(26, 26, 26, 0.24);
$elevationPlatformPressedSurfaceBackgroundColor: rgb(255, 255, 255);
$elevationPlatformPressedSurfaceBorderColor: none;
$elevationPlatformPressedSurfaceBoxShadow: 0 4px 8px 0 rgba(26, 26, 26, 0.24);

// Branded Elevation //
$elevationBrandedBackgroundColor: rgb(255, 255, 255);
$elevationBrandedBorderColor: rgb(255, 255, 255);
$elevationBrandedRestingBoxShadow: 0 4px 24px 0 rgba(26, 26, 26, 0.24);
$elevationBrandedLiftedBoxShadow: 0 4px 40px 0 rgba(26, 26, 26, 0.24);
$elevationBrandedPressedBoxShadow: 0 4px 8px 0 rgba(26, 26, 26, 0.24);

// Platform Shape //
$shapeLargeComponentSharp: 0;
$shapeLargeComponentSoft: 4px;
$shapeLargeComponentRounded: 24px;
$shapeMediumComponentSharp: 0;
$shapeMediumComponentSoft: 4px;
$shapeMediumComponentSquircle: 16px;
$shapeSmallComponentSharp: 0;
$shapeSmallComponentSoft: 4px;
$shapeSmallComponentSquircle: 12px;
$shapeSmallComponentRounded: 100%;
$shapeLargeComponentSquircle: 16px;
$shapeMediumComponentRounded: 16px;

// Branded Shape //
$shapeBrandedSharp: 0;
$shapeBrandedSoft: 4px;
$shapeBrandedRound: 24px;
$shapeBrandedSquircle: 16px;
$shapeBranded: 0;
$shapeBrandedSmallTL: 24px;
$shapeBrandedSmallTR: 24px;
$shapeBrandedSmallBR: 24px;
$shapeBrandedSmallBL: 24px;
$shapeBrandedSmall: ($shapeBrandedSmallTL $shapeBrandedSmallTR $shapeBrandedSmallBR $shapeBrandedSmallBL);
$shapeBrandedMediumTL: 16px;
$shapeBrandedMediumTR: 16px;
$shapeBrandedMediumBR: 16px;
$shapeBrandedMediumBL: 16px;
$shapeBrandedMedium: ($shapeBrandedMediumTL $shapeBrandedMediumTR $shapeBrandedMediumBR $shapeBrandedMediumBL);
$shapeBrandedLargeTL: 16px;
$shapeBrandedLargeTR: 16px;
$shapeBrandedLargeBR: 16px;
$shapeBrandedLargeBL: 16px;
$shapeBrandedLarge: ($shapeBrandedLargeTL $shapeBrandedLargeTR $shapeBrandedLargeBR $shapeBrandedLargeBL);

// Platform Spacing //
$spacingPlatformNano: 4px;
$spacingPlatformTiny: 8px;
$spacingPlatformSmall: 16px;
$spacingPlatformBase: 24px;
$spacingPlatformBig: 40px;
$spacingPlatformHuge: 64px;
$spacingPlatformGigantic: 104px;

// Branded Spacing //
$spacingBrandedCompact: 8px;
$spacingBrandedCozy: 16px;
$spacingBrandedComfortable: 24px;

// Motion Time  //
$motionTimeNano: 50ms;
$motionTimeExtraFast: 100ms;
$motionTimeFast: 150ms;
$motionTimeModerate: 200ms;
$motionTimeModerateSlow: 250ms;
$motionTimeSlow: 300ms;
$motionTimeExtraSlow: 500ms;

// Motion Easing  //
$motionTimingFunctionStandard: cubic-bezier(0.5, 0, 0.2, 1);
$motionTimingFunctionAcceleration: cubic-bezier(0.4, 0, 0.9, 0.3);
$motionTimingFunctionDeceleration: cubic-bezier(0.2, 0.2, 0.1, 1);
$motionTimingFunctionLinear: cubic-bezier(0, 0, 1, 1);

// Branded Type //
$typeBrandedFontFamilyFallbacks: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedDisplayFontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedDisplayFontWeight: 600;
$typeBrandedDisplayCharacterSpacing: -1px;
$typeBrandedDisplayTextTransform: none;
$typeBrandedLargeHeadingFontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedLargeHeadingFontWeight: 800;
$typeBrandedLargeHeadingCharacterSpacing: -0.25px;
$typeBrandedLargeHeadingTextTransform: none;
$typeBrandedMediumHeadingFontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedMediumHeadingFontWeight: 800;
$typeBrandedMediumHeadingCharacterSpacing: 0;
$typeBrandedMediumHeadingTextTransform: none;
$typeBrandedSmallHeadingFontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedSmallHeadingFontWeight: 800;
$typeBrandedSmallHeadingCharacterSpacing: 0.5px;
$typeBrandedSmallHeadingTextTransform: none;
$typeBrandedSubtitle1FontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedSubtitle1FontWeight: 600;
$typeBrandedSubtitle1CharacterSpacing: -0.1pt;
$typeBrandedSubtitle2FontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedSubtitle2FontWeight: 600;
$typeBrandedSubtitle2CharacterSpacing: 0.1pt;
$typeBrandedBody1FontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedBody1FontWeight: 400;
$typeBrandedBody1CharacterSpacing: 0.1pt;
$typeBrandedBody2FontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedBody2FontWeight: 400;
$typeBrandedBody2CharacterSpacing: 0.2pt;
$typeBrandedCaptionFontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedCaptionFontWeight: 400;
$typeBrandedCaptionCharacterSpacing: 0.2pt;
$typeBrandedOverlineFontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedOverlineFontWeight: 600;
$typeBrandedOverlineTextTransform: uppercase;
$typeBrandedOverlineCharacterSpacing: 0.6pt;
$typeBrandedButtonFontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$typeBrandedButtonFontWeight: 800;
$typeBrandedButtonCharacterSpacing: 0.5px;
$typeBrandedButtonTextTransform: none;