// Sizes
$container-width-small: 260px;
$container-width-medium: 300px;
$container-width-large: 500px;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
// =============================================================================

$grid-gutter-widths: (
    xs: 16px,
    sm: 16px,
    md: 16px,
    lg: 24px,
    xl: 24px,
    xxl: 24px
);

$container-max-widths: (
    sm: 578px,
    md: 883px,
    lg: 1023px,
    xl: 1109px,
    xxl: 1110px
);

$grid-breakpoints: (
    xs: 0,
    sm: 578px,
    md: 883px,
    lg: 1023px,
    xl: 1365px,
    xxl: 1564px,
);