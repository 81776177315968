// TEXT: text-align
// =============================================================================
.text--left {
    text-align: left !important;
}

.text--center {
    text-align: center !important;
}

.text--right {
    text-align: right !important;
}

.valign--middle {
    vertical-align: middle !important;
}

// TEXT: white-space
// =============================================================================
.text--nowrap {
    white-space: nowrap !important;
}

// TEXT: text-transform
// =============================================================================
.text--lowercase {
    text-transform: lowercase !important;
}

.text--uppercase {
    text-transform: uppercase !important;
}

.text--capitalize {
    text-transform: capitalize !important;
}

.text--none {
    text-transform: none !important;
}

.text--sentence {
    text-transform: lowercase;

    span::first-letter {
        text-transform: capitalize;
    }
}

// TEXT: text-decoration
// =============================================================================
.text--underline {
    text-decoration: underline !important;
}

.text--no-decoration {
    text-decoration: none !important;
}

// TEXT: text-truncation
// =============================================================================
.text--truncate,
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
