// FLEX: flex-direction
// =============================================================================
.flex--row {
    flex-direction: row !important;
}

.flex--row-reverse {
    flex-direction: row-reverse !important;
}

.flex--col {
    flex-direction: column !important;
}

.flex--col-reverse {
    flex-direction: column-reverse !important;
}

// FLEX: justify-content
// =============================================================================
.flex-justify--start {
    justify-content: flex-start !important;
}

.flex-justify--end {
    justify-content: flex-end !important;
}

.flex-justify--center {
    justify-content: center !important;
}

.flex-justify--between {
    justify-content: space-between !important;
}

.flex-justify--around {
    justify-content: space-around !important;
}

// FLEX: align-content
// =============================================================================
.flex-content--start {
    align-content: flex-start !important;
}

.flex-content--end {
    align-content: flex-end !important;
}

.flex-content--center {
    align-content: center !important;
}

.flex-content--between {
    align-content: space-between !important;
}

.flex-content--around {
    align-content: space-around !important;
}

// FLEX: align-items
// =============================================================================
.flex-items--start {
    align-items: flex-start !important;
}

.flex-items--end {
    align-items: flex-end !important;
}

.flex-items--center {
    align-items: center !important;
}

.flex-items--baseline {
    align-items: baseline !important;
}

.flex-items--stretch {
    align-items: stretch !important;
}

// FLEX: align-self
// =============================================================================
.flex-self--start {
    align-self: flex-start !important;
}

.flex-self--end {
    align-self: flex-end !important;
}

.flex-self--center {
    align-self: center !important;
}

.flex-self--baseline {
    align-self: baseline !important;
}

.flex-self--stretch {
    align-self: stretch !important;
}

// FLEX: flex-wrap
// =============================================================================
.flex--nowrap {
    flex-wrap: nowrap !important;
}

.flex--wrap {
    flex-wrap: wrap !important;
}

// FLEX: flex-grow
// =============================================================================
.flex-grow--0 {
    flex-grow: 0 !important;
}

.flex-grow--1 {
    flex-grow: 1 !important;
}

.flex-grow--2 {
    flex-grow: 2 !important;
}

.flex-grow--3 {
    flex-grow: 3 !important;
}

// FLEX: flex-shrink
// =============================================================================
.flex-shrink--0 {
    flex-shrink: 0 !important;
}

.flex-shrink--1 {
    flex-shrink: 1 !important;
}

.flex-shrink--2 {
    flex-shrink: 2 !important;
}

.flex-shrink--3 {
    flex-shrink: 3 !important;
}
