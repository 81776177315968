// Import the bootstrap grid partials and compile.
// This is taken directly from their grid file, minus the html and box-sizing
// =============================================================================

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

// Grid mixins - Imported directly from node_modules/bootstrap
// =============================================================================

@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/mixins/grid-framework";
@import "../../node_modules/bootstrap/scss/mixins/grid";
@import "../../node_modules/bootstrap/scss/grid";

// Custom Gutters mixin
// =============================================================================

@import "../../node_modules/custom-gutters-bootstrap-plugin/index";

// Remove gutters from a specific column.
// =============================================================================

.no-gutter.col,
.no-gutter[class*="col-"] {
    padding-left: 0;
    padding-right: 0;
}

// Remove clearfix from older bootstraps to fix Safari issue.
// =============================================================================

.container,
.container-fluid,
.row,
.col,
[class*="col-"] {
    box-sizing: border-box;

    &::after,
    &::before {
        box-sizing: border-box;
        content: none !important;
    }
}
