// Variables
// =============================================================================

// Border-radius
$border-radius-xs: 4px;
$border-radius-sm: 8px;
$border-radius-md: 12px;
$border-radius-lg: 16px;
$border-radius-max: 9999px;
$border-radius-100: 100%;

// Border-color
$border-color-default: #e6e6e6;

// Border radius
// =============================================================================
.border-radius--0 {
    border-radius: 0 !important;
}

.border-radius--xs {
    border-radius: $border-radius-xs !important;
}

.border-radius--sm {
    border-radius: $border-radius-sm !important;
}

.border-radius--md {
    border-radius: $border-radius-md !important;
}

.border-radius--lg {
    border-radius: $border-radius-lg !important;
}

.border-radius--max {
    border-radius: $border-radius-max !important;
}

.border-radius--100 {
    border-radius: $border-radius-100 !important;
}

// Border
// =============================================================================
.border--all {
    border: 1px solid $border-color-default !important;
}

.border--0 {
    border: none !important;
}

.border--top {
    border-top: 1px solid $border-color-default !important;
}

.border--right {
    border-right: 1px solid $border-color-default !important;
}

.border--bottom {
    border-bottom: 1px solid $border-color-default !important;
}

.border--left {
    border-left: 1px solid $border-color-default !important;
}
