// Font Style
// =============================================================================

.font-display {
    font-family: $typeBrandedDisplayFontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedDisplayFontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 60px;
    font-weight: $typeBrandedDisplayFontWeight;
    font-weight: var(--typeBrandedDisplayFontWeight);
    letter-spacing: $typeBrandedDisplayCharacterSpacing;
    letter-spacing: var(--typeBrandedDisplayCharacterSpacing);
    line-height: 64px;
}

.font-large-heading {
    font-family: $typeBrandedLargeHeadingFontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedLargeHeadingFontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 34px;
    font-weight: $typeBrandedLargeHeadingFontWeight;
    font-weight: var(--typeBrandedLargeHeadingFontWeight);
    letter-spacing: $typeBrandedLargeHeadingCharacterSpacing;
    letter-spacing: var(--typeBrandedLargeHeadingCharacterSpacing);
    line-height: 40px;
}

.font-medium-heading {
    font-family: $typeBrandedMediumHeadingFontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedMediumHeadingFontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 24px;
    font-weight: $typeBrandedMediumHeadingFontWeight;
    font-weight: var(--typeBrandedMediumHeadingFontWeight);
    letter-spacing: $typeBrandedMediumHeadingCharacterSpacing;
    letter-spacing: var(--typeBrandedMediumHeadingCharacterSpacing);
    line-height: 28px;
}

.font-small-heading {
    font-family: $typeBrandedSmallHeadingFontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedSmallHeadingFontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 20px;
    font-weight: $typeBrandedSmallHeadingFontWeight;
    font-weight: var(--typeBrandedSmallHeadingFontWeight);
    letter-spacing: $typeBrandedSmallHeadingCharacterSpacing;
    letter-spacing: var(--typeBrandedSmallHeadingCharacterSpacing);
    line-height: 24px;
}

.font-subtitle-1 {
    font-family: $typeBrandedSubtitle1FontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedSubtitle1FontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 16px;
    font-weight: $typeBrandedSubtitle1FontWeight;
    font-weight: var(--typeBrandedSubtitle1FontWeight);
    letter-spacing: $typeBrandedSubtitle1CharacterSpacing;
    letter-spacing: var(--typeBrandedSubtitle1CharacterSpacing);
    line-height: 24px;
}

.font-subtitle-2 {
    font-family: $typeBrandedSubtitle2FontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedSubtitle2FontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 14px;
    font-weight: $typeBrandedSubtitle2FontWeight;
    font-weight: var(--typeBrandedSubtitle2FontWeight);
    letter-spacing: $typeBrandedSubtitle2CharacterSpacing;
    letter-spacing: var(--typeBrandedSubtitle2CharacterSpacing);
    line-height: 20px;
}

.font-body-1 {
    font-family: $typeBrandedBody1FontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedBody1FontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 16px;
    font-weight: $typeBrandedBody1FontWeight;
    font-weight: var(--typeBrandedBody1FontWeight);
    letter-spacing: $typeBrandedBody1CharacterSpacing;
    letter-spacing: var(--typeBrandedBody1CharacterSpacing);
    line-height: 24px;
}

.font-body-2 {
    font-family: $typeBrandedBody2FontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedBody2FontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 14px;
    font-weight: $typeBrandedBody2FontWeight;
    font-weight: var(--typeBrandedBody2FontWeight);
    letter-spacing: $typeBrandedBody2CharacterSpacing;
    letter-spacing: var(--typeBrandedBody2CharacterSpacing);
    line-height: 20px;
}

.font-caption {
    font-family: $typeBrandedCaptionFontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedCaptionFontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 12px;
    font-weight: $typeBrandedCaptionFontWeight;
    font-weight: var(--typeBrandedCaptionFontWeight);
    letter-spacing: $typeBrandedCaptionCharacterSpacing;
    letter-spacing: var(--typeBrandedCaptionCharacterSpacing);
    line-height: 16px;
}

.font-overline {
    font-family: $typeBrandedOverlineFontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedOverlineFontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 10px;
    font-weight: $typeBrandedOverlineFontWeight;
    font-weight: var(--typeBrandedOverlineFontWeight);
    letter-spacing: $typeBrandedOverlineCharacterSpacing;
    letter-spacing: var(--typeBrandedOverlineCharacterSpacing);
    line-height: 12px;
    text-transform: uppercase;
}

.font-button {
    font-family: $typeBrandedButtonFontFamily, $typeBrandedFontFamilyFallbacks;
    font-family: var(--typeBrandedButtonFontFamily), var(--typeBrandedFontFamilyFallbacks);
    font-size: 16px;
    font-weight: $typeBrandedButtonFontWeight;
    font-weight: var(--typeBrandedButtonFontWeight);
    letter-spacing: $typeBrandedButtonCharacterSpacing;
    letter-spacing: var(--typeBrandedButtonCharacterSpacing);
    line-height: 16px;
}

// Font Headings
// =============================================================================
.font-content-heading--district {
    @extend .font-large-heading;

    margin-bottom: $spacingPlatformBase;
    margin-top: $spacingPlatformHuge;
}

.font-content-heading--activity {
    @extend .font-medium-heading;

    margin-bottom: $spacingPlatformBase;
    margin-top: $spacingPlatformBig;
}

.font-content-heading--activity-description {
    @extend .font-medium-heading;

    margin-bottom: $spacingPlatformTiny;
    margin-top: $spacingPlatformBig;
}

.font-content-heading--activity-description + .font-content-heading--activity-description-label {
    @extend .font-body-1;

    margin-bottom: $spacingPlatformBase;
}

.font-content-heading--high-emphasis {
    @extend .font-small-heading;

    margin-bottom: $spacingPlatformSmall;
    margin-top: $spacingPlatformBig;
}

.font-content-heading--low-emphasis {
    @extend .font-overline;

    margin-bottom: $spacingPlatformSmall;
    margin-top: $spacingPlatformBase;
}

.font-content-heading--table {
    @extend .font-overline;

    margin-bottom: $spacingPlatformSmall;
    margin-top: $spacingPlatformBase;
}