.screenreader-only,
.sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    height: max-content;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    width: max-content;
}

.screenreader-only-focusable,
.sr-only-focusable {
    &:active,
    &:focus {
        clip: auto;
        clip-path: none;
        height: auto;
        overflow: visible;
        position: static;
        white-space: normal;
        width: auto;
    }
}
